
.SignIn-page {
    background: url(../../assets/img/background.png) center/cover no-repeat;
    color: #FFFFFF;
    height: 100%; 
}
.container {
    flex-direction: column;
}

.box-title {
   font-family: Droid Arabic Kufi;
font-size: 24px;
font-weight: 700;
line-height: 45.74px;
text-align: right;

}

.box-text {
    font-size: 14px;
    direction: rtl;
}

.box-input {
    position: relative;
    width: 100%;
}



.box-input-icon {
    width: 17.3px;
    height: 17.5px;
    position: absolute;
    top: 25%;
    left: 5%;
}

.left-style {
    left: 10%;
}

.box-input-center {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}



@media (max-width: 600px) {
    .box-input-center {
        flex-direction: column;
        gap: 10px;
    }
    .left-style{
        left:5% ;
    }
}

.box-link {
    color: #FFFFFF;
    font-size: 14px;
    z-index: 1;
}

.error {
    color: red;
    font-size: 14px;
    direction: rtl;
}