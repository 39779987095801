.box {
    position: relative;
    width: 80vw;
    max-width: 576px;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: hwb(0 100% 0% / 0.329);
    border-radius: 40px;
    margin-bottom: 10%;

}

.box::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 40px;
    padding: 2px;
    background: linear-gradient(45deg, #DD2B1C66, #FFFFFF, #DD2B1C66);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}


.box-cont {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    gap: 15px;
    padding: 42px;
}

@media (min-width: 0px) and (max-width: 800px) {
    .box {
        width: 85vw;
    }
    .full-width{
        width: 100%;
    }
    .box-cont{
        padding: 24px;
    }
}
.max-width-box {
    max-width: 731px; 
}
.box, .box-cont {
    position: relative; 
}