
.box-input-context {
    text-align: end;
    padding-right: 4%;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: none;
    border-bottom: 2px solid #DD2B1C66;
    width: 100%;
    color: #000;
    height: 44px;
    font-size: 14px;
}

.box-input-context:focus {
    outline: none;
    background-color: #ffffff88;
}