.box-button-border {
    width: 98%;
    height: 60px;
    border-radius: 30px;
    background: linear-gradient(91.66deg, rgba(255, 153, 0, 0.3) -71.32%, rgba(221, 43, 28, 0.3) 80.89%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-button {
    color: #FFFFFF;
    z-index: 1;
    font-family: Droid Arabic Kufi;
    font-size: 16px;
    font-weight: 700;
    line-height: 30.49px;
    width: 96%;
    height: 42px;
    border-radius: 21px;
    border: none;
    background: linear-gradient(90deg, #F43626 55.19%, #8E2016 100%);
}

.box-button:focus {
    outline: none;
}

@media (max-width:800px) {
    .box-button-border {
        background: #ECA09966;
    }

    .box-button {
        width: 90%;
    }

}