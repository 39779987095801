.SignUp-page {
    background: url(../../assets/img/background.png)center/cover no-repeat;
    color: #FFFFFF;
    height: 100%;
}

.container {
    flex-direction: column;
}

.box-title-1 {
    font-family: Droid Arabic Kufi;
    font-size: 32px;
    font-weight: 700;
    line-height: 60.98px;
    text-align: right;

}

.box-text {
    font-size: 14px;
    direction: rtl;
    text-align: center;
}

.box-input {
    position: relative;
    width: 100%;
}

.box-input-context-2 {
    text-align: start;
    padding-right: 5%;
    background-color: #FFFFFF;
    border-radius: 10px;

    border: none;
    border-bottom: 2px solid #DD2B1C66;
    width: 100%;
    color: #858585;
    height: 44px;
    font-size: 14px;
    direction: rtl;
    -webkit-appearance: none;
    -webkit-min-logical-width: 100%;
    box-sizing: border-box;
    /* Ensure padding doesn't cause overflow */
}
.box-input-context-3 {
    text-align: center;
    padding-right: 5%;

    background-color: #FFFFFF;
    border-radius: 10px;
    width: 100%;
    border: none;
    border-bottom: 2px solid #DD2B1C66;
    width: 100%;
    color: #858585;
    height: 44px;
    font-size: 14px;
    display: flex;
    justify-content: end;
    /* Ensure padding doesn't cause overflow */
}
.inputs{
    border: none;
    background-color: transparent;
    text-align: center;
    direction: rtl;

}
.inputs:focus{
    outline: none;
}
.date-img{
    width: 17px;
   
}
.img-placement{
    padding-right:10% ;
}
.label-date{
    top: 14px;
    position: absolute;
    right: 5%;
    text-align:end;
    background-color: #FFFFFF;
    width: 70%;
}
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {

        /* Safari-specific styles */
        input[type="date"] {
            direction: rtl;
            /* Reverse the direction for Safari */
            text-align: right;
            /* Align content to the right */
            -webkit-appearance: none;
            /* Remove default Safari appearance */
        }
    }
}

input[type="date"] {
    -webkit-appearance: none;
    /* Removes default Safari style */
    appearance: none;
    /* Cross-browser support */
    padding-right: 10px;
    /* Adjust padding to space the text from the icon */
    /* Align the text to the left (or start) */
    width: 100%;
    box-sizing: border-box;
    /* Ensure no overflow with padding */
}

input[type="date"]::placeholder {
    /* Align placeholder text */
    color: #858585;
}

.box-input-context-2:focus {
    outline: none;

    background-color: #ffffff88;
}

.box-input-icon {
    width: 17.3px;
    height: 17.5px;
    position: absolute;
    top: 25%;
    left: 5%;
}

.left-style {
    left: 10%;

}


.date-input {
    text-align: end;
    /* Default alignment */

}

.date-input:focus {
    text-align: end;
    /* Alignment on focus */
    direction: rtl;
    width: 100%;
}

.date-input::-webkit-calendar-picker-indicator {
    filter: invert(1);

}
.box-input-center {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}
@media (min-width: 600px) {
    .box-input-center {
        flex-direction: column;
        gap: 10px;
    }
    .box-input-context-2{
        text-align: start;
    }
}
@media (max-width: 600px) {
    .img-placement{
        padding-right:8% ;
    }
    .box-text {
        text-align: center;
    }
}
@media (max-width: 300px) {
    .img-placement{
        padding-right:6% ;
    }
    
}
@media (min-width: 900px) {
    .img-placement{
        padding-right:0% ;
    }
    
}
.box-link {
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    z-index: 1;
}

.error {
    color: red;
    font-size: 14px;
    direction: rtl;
}