.box-button-border-2 {
    width: 98%;
    height: 60px;
    border-radius: 30px ;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.box-button-2 {
    color: #FFFFFF;
    z-index: 1;
    font-size: 16px;
    width: 92%;
    height: 42px;
    border-radius: 21px;
    border: none;
    font-weight: 700;
    background: linear-gradient(90deg, #F43626 55.19%, #8E2016 100%);
}

.box-button-2:focus {
    outline: none;
}
.box-button-colorWhite {
    background: linear-gradient(90deg, #ffffff 55.19%, #eeeeee 100%);
    color: #F43626; 
    font-weight: 700;
}

@media (max-width: 768px) {
.box-button-border-2{
    height: 52px;
    width: 95% ;
}
}