.title-respon {
    font-size: 32px;
    font-weight: 700;
    line-height: 60.98px;
    text-align: right;
    z-index: 2;
    height: fit-content;
    /* padding-top: 20px; */
    width: fit-content;
    display: flex;
    justify-content: center;
}

.old-visits {
    width: 24%;
    min-width: 170px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #F43626;
    background-color: transparent;
    color: #F43626;
    font-size: 16px;
    font-weight: 400;
    line-height: 30.49px;
    text-align: right;
    text-align: center;
    z-index: 10;
}

.old-visits:focus {
    background-color: #F43626;
    color: #ffff;
}

.check-mark-cont {
    width: 106px;
    height: 106px;
    /* margin-left: -30px;
    margin-top: -30px;
    padding: 8.83px; */
    background: #EAFDE3;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.cont {
    width: 100%;
    height: fit-content;
    border-radius: 80px;
    padding-bottom: 40px;
    background: #DD2B1C1A;
}

.cont-info {
    direction: rtl;
}

.to-call {
    font-family: Droid Arabic Kufi;
    font-size: 20px;
    font-weight: 700;
    line-height: 38.12px;
    text-align: right;
}

.info-text {
    white-space: nowrap;
}

.pdf-pos {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 6.5%;
}

.pdf-cont {
    width: 321px;
    height: 67px;
    border-radius: 10px;
    border: none;
    background-color: #ffff;
    color: #F43626;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 150px;
}

.pdf-cont:focus {
    outline: none;
}

.pdf-manu {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
}

.pdf-modfi {
    font-size: 14px;
    font-weight: 400;
    line-height: 26.68px;
    text-align: right;
    color: #858585;
}

.img-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.img-placment {
    width: 370px;
    height: 225px;
    border-radius: 20px;
    z-index: 1;
    padding: 0;
}
.img-show{
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.shadow-1 {
    position: fixed;
    z-index: -1;
    left: 0px;
    top: 470px;
    width: 574.68px;
    height: 303.31px;
    transform: rotate(-41.46deg);
}

.shadow-2 {
    position: fixed;
    left: 300px;
    z-index: -1;
    width: 456px;
    height: 350px;
    top: 449.07px;
    
    transform: rotate(26.76deg);
}

.shadow-3 {
    position: fixed;
    left: 700px;
    z-index: -1;
    width: 456px;
    height: 350px;
    top: 604px;
    
    transform: rotate(-16deg);
}
.shadow-4 {
    position: fixed;
    left: 850px;
    z-index: -1;
    width: 756px;
    height: 450px;
    top: 230px;
    
}

@media (max-width: 768px) {
    .title-respon {
        font-size: 20px;
    }

    .check-mark-cont {
        width: 84px;
        height: 84px;
        border-radius: 50px;
    }

    .pdf-cont {
        width: 50%;
        height: 67px;
    }

    .pdf-pos {
        padding: 0px;
        justify-content: center;
    }

    .info-text {
        white-space: wrap;
    }

    .img-cont {
        flex-direction: column;
    }
    .shadow-4 {
        left: 50px;
        top: 250px;
    }
    .shadow-1{
        left: -100px;
        top: 450px;
    }
    .shadow-3{
        display: none;
    }
}

@media (min-width: 768px) and (max-width:1200px) {
    .img-cont {
        display: flex;
        flex-direction: column;
    }
}