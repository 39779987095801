.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: max-content;
    position: relative;
    scroll-behavior: auto;
}

.boxVisit {
    width: 95vw;
    max-width: 1768px;
    height: max-content;
    background: #DD2B1C1A;
    border-radius: 40px;
    margin-bottom: 10%;
    padding: 32px;
    padding-top: 0px;
}

.boxVisit::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 40px;
    padding: 2px;
    background: linear-gradient(109.73deg, #ffffff 5.22%, #F43626 48.61%, #FFFFFF 84.08%);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

}

.boxVisit-cont {
    width: 100%;
    height: max-content;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    padding-right: 0;

}

.back-btn {
    border: none;
    background-color: transparent;
    width: max-content;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 10px;
}

.back-text {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: left;
    padding-top: 10px;
    align-items: center;
}

.back-btn:focus {
    outline: none;
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 38.12px;
    text-align: right;
    padding-top: 10px;
    white-space: nowrap;
}

.visit-num {
    font-size: 20px;
    font-weight: 400;
    line-height: 38.12px;
    text-align: right;
    color: #F43626;
    padding-top: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    text-align: center;
}

.card {
    width: 90%;
    height: max-content;
    border-radius: 15px;
}

.card-title {
    margin-top: 20px;
    width: 98.5%;
    border-right: 4px solid #F43626;
    height: 45px;
    border-radius: 10px 0px 0px 10px;
    background: #FCE9E7;

}

.card-visit-number {
    font-size: 20px;
    font-weight: 700;
    line-height: 38.12px;
    text-align: right;
    display: flex;
    justify-content: space-between;
    color: #F43626;
    padding-right: 30px;
    padding-left: 30px;
}

.card-visit-title {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
}

.card-visit-date {
    font-size: 16px;
    font-weight: 400;
    line-height: 30.49px;
    text-align: right;
    padding-top: 10px;
}

.card-info-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 30.49px;
    text-align: right;
    padding: 0px;


}

.card-info {
    font-size: 16px;
    font-weight: 400;
    line-height: 30.49px;
    text-align: right;
    color: #858585;

}

.custom-list {
    list-style-position: inside;
    direction: rtl;
    padding-right: 0;

}

.custom-list li {
    display: list-item;
    white-space: nowrap;
}

.custom-list li::marker {
    color: red;
}

.custom-list p {
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    width: 60%;
}

.custom-list div {
    margin: 0;
    display: inline-block;
    width: 57px;
    height: 25px;
    border-radius: 4px;
    background-color: #85858538;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 25.49px;
}

.openion {
    font-size: 16px;
    font-weight: 400;
    line-height: 30.49px;
    text-align: right;
    color: #858585;
}

.shadow-1 {
    position: fixed;
    z-index: -1;
    transform: rotate(-26.89deg);
    width: 1036.62px;
    height: 642.89px;
    top: 150px;
    left: -800.47px;
}

.shadow-2 {
    position: fixed;
    z-index: -1;
    transform: rotate(16.89deg);
    width: 1236.62px;
    height: 842.89px;
    top: -450px;
    left: -700.47px;

}

.shadow-3 {
    position: fixed;
    z-index: -1;
    transform: rotate(0);
    width: 1036.62px;
    height: 642.89px;
    top: 50px;
    left: 300.47px;

}

.shadow-4 {
    position: fixed;
    z-index: -1;
    transform: rotate(0);
    width: 1036.62px;
    height: 642.89px;
    top: 70px;
    left: 800.47px;
}

.boxVisit,
.boxVisit-cont {
    position: relative;
}

.more {
    width: 121px;
    height: 47px;
    border-radius: 10px;
    border: 1px;
    background-color: #fff;
    border: 1px solid #F43626;
    color: #F43626;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    z-index: 100000000;
}


/* Image rotation */
.more img {
    transition: transform 0.3s ease-in-out;
}

.more img.rotated {
    transform: rotate(180deg);
}

/* Div transition */
.content-div {
    height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
}

.boxVisit.expanded .content-div {
    max-height: fit-content;
    /* Adjust based on expected content size */
    transition: max-height 1.3s ease-in-out;
    overflow-y: scroll;
}


.content {
    max-height: 550px;
    overflow: hidden;
    transition: max-height 1.3s ease-in-out;
}

.content.expanded {
    max-height: fit-content;
    overflow: auto;
}

.overFlow {
    overflow-y: auto;
    overflow-x: hidden;
}

.warning-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 30.49px;
    text-align: right;
    padding: 0;
    margin: 0;
}
.mostLiked{
    border: none;
    background-color: transparent;
    text-align: end;
    width: 100%;
}

.mostLiked:focus{
    outline: none;
}
.makeTheChange{
    width: 100px;
height: 40px;
border-radius: 8px ;
color: #F43626;
border: 1px solid #F43626 ;
background-color: transparent;
font-size: 16px;
font-weight: 400;
line-height: 30.49px;
margin: 30px;
}
@media (max-width: 400px) {
    .overFlow {
        overflow-x: auto;

    }
}

@media (min-width: 0px) and (max-width: 800px) {
    .boxVisit {
        width: 85vw;
    }

    .full-width {
        width: 100%;
    }

    .boxVisit-cont {
        padding: 0px;
    }
}

@media (max-width: 992px) {
    .content {
        width: 100%;
    }

    .card {
        border-radius: 15px;
        height: max-content;
        width: 100%;
    }

    .card-visit-number {
        padding-right: 5px;
        padding-left: 5px;
    }

    .title {
        font-size: 16px;
    }

    .visit-num {
        font-size: 16px;
    }

    .line-under {
        border-bottom: 1px solid #DEDEDE
    }

    .card-info-title {
        margin-left: 1%;
    }

    .custom-list p {
        width: 75%;
    }

}