
.rate-page {
    background: url(../../assets/img/background2.png) center/cover no-repeat;
    color: #FFFFFF;
    height: 100%; 
}
.box-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 45.74px;
    text-align: center;
}




.box-title-cont {
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 0;
    height: 10px;
    padding-bottom: 15px;
}

.box-title-input {
    font-size: 14px;
    font-weight: 700;
    line-height: 26.68px;
    text-align: right;

}

.custom-select {
    position: relative;
    width: 200px;
}

.box-selecte-item-cont {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.box-selecte-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
}

.box-selecte-item select {
    width: 144px;
    height: 44px;
    text-align: center;
    color: #858585;
    border-radius: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    border-bottom: 2px solid #DD2B1C66;
}

.box-selecte-item select:focus {
    outline: none;
}

.select-arrow {
    position: absolute;
    right: 110px;
    top: 50%;
    
    transform: translateY(-50%);
    transition: transform 0.2s ease;
    width: 11px;
}

.select-arrow.rotate {
    transform: translateY(-50%) rotate(180deg);
}

.add-box-selecte-item-cont {
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 0px;
    height: fit-content;
    border: none;
    background-color: transparent;

}

.add-input {
    text-align: end;
    background: hsla(0, 0%, 100%, 0.5);
    border-radius: 10px;
    border-bottom: 2px solid #DD2B1C66;
    width: 100%;
    color: #000;
    height: 44px;
    font-size: 14px;
    margin: 0px;
}

.add-select {
    background: hsla(0, 0%, 100%, 0.5);
}

.add-title {
    color: #858585;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 0px;
}

.add-img {
    display: flex;
    height: 100%;
    
    align-items: center;
}

.text-area {
    width: 100%;
    height: 143px;
    border-radius: 15px;
    direction: rtl;
    padding: 10px;
    font-size: 14px;
    border-bottom: 2px solid #DD2B1C66;
}

.text-area:focus {
    outline: none;
}

@media (max-width: 768px) {
    .box-selecte-item select {
        width: 110px;
    }

    .select-arrow {
        right: 90px;
    }
}

@media (min-width: 420px) and (max-width: 768px) {
    .add-title{
        width: 82%;
        
    }
    
}



@media (max-width: 420px) {
    .add-title{
        width: 75%;
        font-size: 12px;
        width: fit-content;
       
    }
    .box-selecte-item select {
        width:70px;
        padding-left: 10px;
        font-size: 14px;
    }
    .box-cont {
        padding: 10px;
    }
    .select-arrow{
        left: 1px;
    }
    .add-img-w{
        width: 17px;
    }
}
/* css of input */

.input-context {
    text-align: end;
    padding-right: 2%;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: none;
    border-bottom: 2px solid #DD2B1C66;
    width: 100%;
    color: #000;
    height: 44px;
    font-size: 14px;

}

.input-context:focus {
    outline: none;
    background-color: #ffffff88;
}
.input-context-text{
    text-align: end;
    padding-right: 2%;
    background-color: transparent;
    border: none;
    
    width: 100%;
    color: #000;
    height: 44px;
}

.input-context-text:focus {
    outline: none;
    background-color: #ffffff88;
}
.input-context-img{
    display: flex;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 44px;
}
.input-context-img:focus{
    outline: none;
}
.error {
    color: red;
    font-size: 14px;
    direction: rtl;
    
}